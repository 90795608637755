const slideElements = [
  {
    id: 1,
    title: 'Marktentwicklung: Geschäfts- und Vertriebsmodelle sowie Wettbewerber',
    style: {},
    img: 'slide_01_marktentwicklung.png',
    slug: 'marktentwicklung',
    overlay: 'overlay_01_marktentwicklung.webm',
    fallback: 'fallback_01_marktentwicklung.png',
  },
  {
    id: 2,
    title: 'Connected Car / Autonomous Car',
    style: {},
    img: 'slide_02_connected_car.png',
    slug: 'connected_car',
    overlay: 'overlay_02_connected_car.webm',
    fallback: 'fallback_02_connected_car.png',
  },
  {
    id: 3,
    title: 'Neue Kund:innen',
    style: {},
    img: 'slide_03_neukunden.png',
    slug: 'neukunden',
    overlay: 'overlay_03_neukunden.webm',
    fallback: 'fallback_03_neukunden.png',
  },
  {
    id: 4,
    title: 'Ladeinfrastruktur & Tankinfrastruktur',
    style: {},
    img: 'slide_04_ladeinfrastruktur_tankinfrastruktur.png',
    slug: 'ladeinfrastruktur_tankinfrastruktur',
    overlay: 'overlay_04_ladeinfrastruktur_tankinfrastruktur.webm',
    fallback: 'fallback_04_ladeinfrastruktur_tankinfrastruktur.png',
  },
  {
    id: 5,
    title: 'Neue Antriebsvarianten',
    style: {},
    img: 'slide_05_neue_antriebsvarianten.png',
    slug: 'neue_antriebsvarianten',
    overlay: 'overlay_05_neue_antriebsvarianten.webm',
    fallback: 'fallback_05_neue_antriebsvarianten.png',
  },
  {
    id: 6,
    title: 'Fachkräfte und Beschäftigung',
    style: {},
    img: 'slide_06_fachkraefte_und_beschaeftigung.png',
    slug: 'fachkraefte_und_beschaeftigung',
  },
  {
    id: 7,
    title: 'Zukunftswerkstatt 4.0',
    style: {},
    img: 'slide_07_zukunftswerkstatt.png',
  }
]

module.exports = {
  slideElements
}
