<template>
  <div class="start">
    <img src="../assets/img/latlong.png" class="latlong">
    <Brand />
    <div
        class="rotate-slider"
        ref="sliderElement">
      <ul
          class="slides"
          ref="slidesContainer">
        <li
            v-for="(slide, idx) in slides"
            :key="idx"
            :style="slide.style"
            :class="{ active:  slide.id === activeSlide }">
          <img :src="`${publicPath}static/img/${slide.img}`">
          <img :src="`${publicPath}static/img/${slide.fallback}`"
              v-if="slide.fallback && isSafari()">
          <video
              v-if="slide.overlay && slide.id === activeSlide && !subContent.description && !isSafari()"
              :src="`${publicPath}static/video/${slide.overlay}`"
              type="video/webm" muted autoplay loop />
          <transition
              enter-active-class="animated bounceInUp"
              leave-active-class="animated bounceOutDown">
            <div
                class="inner"
                v-if="slide.id === activeSlide && !showMore">
              <h1 v-html="slide.title" />
              <button
                  v-if="slide.id !== 7"
                  class="btn btn-primary"
                  @click="activateSubnav(slide)">
                Mehr erfahren
              </button>
              <button
                  v-if="slide.id === 7"
                  class="btn btn-primary"
                  @click="visitAutohaus()">
                Jetzt besuchen
              </button>
              <Edges />
            </div>
          </transition>
        </li>
      </ul>
    </div>
    <transition
        enter-active-class="animated bounceInDown"
        leave-active-class="animated bounceOutDown">
      <div v-if="showMore" class="subnav-container">
        <button
            class="close"
            @click="close()">
          <svg viewBox="0 0 50 50">
            <polygon points="2.707,48.707 25,26.414 47.293,48.707 48.707,47.293 26.414,25 48.707,2.707 47.293,1.293 25,23.586 2.707,1.293 1.293,2.707 23.586,25 1.293,47.293"/>
          </svg>
        </button>
        <ul class="subnav">
          <li
              v-for="(child, idx) in activeSlideContent.children"
              :key="idx"
              :class="{ active: subContent.order_id == idx + 1 }">
            <span class="id" v-text="idx + 1" />
            <span class="title" v-text="child.title" />
            <div class="touchpoint">
              <TouchpointSimple
                  @click="toggleContent(child)"
                  v-if="subContent.order_id != idx + 1" />
            </div>
          </li>
        </ul>
        <transition
            enter-active-class="animated slideInUp"
            leave-active-class="animated slideOutDown"
            mode="out-in">
          <div
              class="content"
              v-if="subContent.description">
            <div class="image">
              <img :src="`${publicPath}static/img/${activeSlideContent.img}`">
              <img :src="`${publicPath}static/img/${activeSlideContent.fallback}`"
                  class="fallback"
                  v-if="activeSlideContent.fallback && isSafari()">
              <video
                  v-if="activeSlideContent.overlay && !isSafari()"
                  :src="`${publicPath}static/video/${activeSlideContent.overlay}`"
                  type="video/webm" muted autoplay loop />
            </div>
            <transition
                name="slideRight"
                mode="out-in">
              <div
                  class="body"
                  v-html="subContent.description"
                  :key="subContent.id" />
            </transition>
          </div>
        </transition>
      </div>
    </transition>
    <transition name="fade">
      <ul
          class="pagination"
          v-if="!showMore">
        <li
            v-for="slide in slideElements"
            :key="slide.id"
            :class="{ active: slide.id === activeSlide }"
            @click="rotateTo(slide.id)">
          <span v-text="`0${slide.id}.`" />
          <span class="title" v-html="slide.title" />
        </li>
      </ul>
    </transition>
    <transition name="fade">
      <div v-if="!showMore">
        <button
            class="btn btn-primary btn-prev"
            @click="rotate('prev')">
          <svg viewBox="0 0 22 37">
            <path d="M19.9688 2.00049L2.99825 18.5843L19.9688 35.168" />
          </svg>
        </button>
        <button
            class="btn btn-primary btn-next"
            @click="rotate('next')">
          <svg viewBox="0 0 22 37">
            <path d="M19.9688 2.00049L2.99825 18.5843L19.9688 35.168" />
          </svg>
        </button>
      </div>
    </transition>
    <svg>
      <defs>
        <clipPath id="slideClip">
          <path ref="clipPath" />
        </clipPath>
      </defs>
    </svg>
  </div>
</template>


<script>
import { ref, onMounted, getCurrentInstance, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { isSafari } from '@/tools.js'
import { slideElements } from '@/data.js'
import Brand from '@/components/Brand'
import Edges from '@/components/Edges'
import TouchpointSimple from '@/components/TouchpointSimple'

// deep clone slides
const slideData   = [...slideElements, ...JSON.parse(JSON.stringify(slideElements))]

export default {
  name: 'Start',

  components: {
    Brand,
    Edges,
    TouchpointSimple
  },

  setup () {
    const instance            = getCurrentInstance()
    const emitter             = instance.appContext.config.globalProperties.emitter
    const axios               = instance.appContext.config.globalProperties.axios
    const router              = useRouter()
    const route               = useRoute()
    const publicPath          = process.env.BASE_URL
    const slides              = ref(slideData)
    const sliderElement       = ref(null)
    const slidesContainer     = ref(null)
    const clipPath            = ref(null)
    const activeSlide         = ref(null)
    const activeSlideContent  = ref(null)
    const subContent          = ref({})
    const showMore            = ref(null)
    const slider              = {}
    let currentRotation       = 0

    const init = () => {
      // config
      slider.slideHeight      = window.innerHeight / 1.1
      slider.slideWidth       = window.innerWidth / 1.5
      slider.slideCount       = slides.value.length
      slider.slideAngle       = 360 / slider.slideCount
      slider.halfAngleRad     = slider.slideAngle / 2 * Math.PI / 180
      slider.innerRadius      = 1 / Math.tan(slider.halfAngleRad) * slider.slideWidth / 2
      slider.outerRadius      = Math.sqrt(Math.pow(slider.innerRadius + slider.slideHeight, 2) + (Math.pow((slider.slideWidth / 2), 2)))
      slider.upperArcHeight   = slider.outerRadius - (slider.innerRadius + slider.slideHeight)
      slider.lowerArcHeight   = slider.innerRadius - (slider.innerRadius * (Math.cos(slider.halfAngleRad)))
      slider.slideFullWidth   = (Math.sin(slider.halfAngleRad) * slider.outerRadius) * 2
      slider.slideFullHeight  = slider.upperArcHeight + slider.slideHeight + slider.lowerArcHeight
      slider.slideSidePadding = (slider.slideFullWidth - slider.slideWidth) / 2
      slider.fullArcHeight    = slider.outerRadius - (slider.outerRadius * (Math.cos(slider.halfAngleRad)))
      slider.lowerArcOffset   = (slider.slideFullWidth - (Math.sin(slider.halfAngleRad) * slider.innerRadius * 2)) / 2

      // reset vals
      const slideNr     = parseInt(route.params.slide)
      currentRotation   = -(slideNr-1) * slider.slideAngle
      activeSlide.value = slideNr
      showMore.value    = false
      subContent.value  = {}

      /* Set height and width of slider element */
      sliderElement.value.style.width  = `${slider.slideWidth}px`
      sliderElement.value.style.height = `${slider.slideHeight}px`

      /* Set height and width of slides container and offset width*/
      slidesContainer.value.style.height    = `${slider.outerRadius * 2}px`
      slidesContainer.value.style.width     = `${slider.outerRadius * 2}px`
      slidesContainer.value.style.transform = `translateX(-50%) rotate(${currentRotation}deg)`
      slidesContainer.value.style.top       = `${-slider.upperArcHeight}px`

      /* Generate path for slide clipping */
      const coords = `M 0 ${slider.fullArcHeight} A ${slider.outerRadius} ${slider.outerRadius} 0 0 1 ${slider.slideFullWidth} ${slider.fullArcHeight} L ${slider.slideFullWidth - slider.lowerArcOffset} ${slider.slideFullHeight} A ${slider.innerRadius} ${slider.innerRadius} 0 0 0 ${slider.lowerArcOffset} ${slider.slideFullHeight} Z`
      clipPath.value.setAttribute('d', coords)

      /* Apply styles to each slide */
      slides.value.forEach((slide, i) => {
        // Set distance from point of rotation
        slide.style.transformOrigin = `center ${slider.innerRadius + slider.slideHeight}px`
        // Set slide Height and Width
        slide.style.height = `${slider.slideHeight}px`
        slide.style.width  = `${slider.slideWidth}px`
        // Set calculated padding for width, upper arc height, and lower arc height
        slide.style.padding = `${slider.upperArcHeight}px ${slider.slideSidePadding}px ${slider.lowerArcHeight}px ${slider.slideSidePadding}px`
        /* Offset container Arc Height */
        slide.style.top = `${slider.upperArcHeight}px`
        /* Offset Width, then Rotate Slide, then offset individual Top Arcs  */
        slide.style.transform = `translateX(-50%) rotate(${slider.slideAngle * i}deg) translateY(${-slider.upperArcHeight}px)`
      })
    }

    const rotate = (direction) => {
      emitter.emit('play-btn-sound')
      if (direction === 'next') {
        currentRotation = currentRotation - slider.slideAngle
        // calculate current slide
        if (activeSlide.value === slideElements.length) activeSlide.value = 1
        else activeSlide.value++
      } else {
        currentRotation = currentRotation + slider.slideAngle
        // calculate current slide
        if (activeSlide.value === 1) activeSlide.value = slideElements.length
        else activeSlide.value--
      }
      showMore.value = false
      slidesContainer.value.style.transform = `translateX(-50%) rotate(${currentRotation}deg)`
      router.push({
        params: {
          slide: activeSlide.value
        },
        query: {
          processBack: route.query.processBack,
          products: route.query.products
        }
      })
    }

    const rotateTo = (pos) => {
      emitter.emit('play-btn-sound')
      currentRotation = -(pos-1) * slider.slideAngle
      activeSlide.value = pos
      showMore.value = false
      slidesContainer.value.style.transform = `translateX(-50%) rotate(${currentRotation}deg)`
      router.push({
        params: {
          slide: activeSlide.value
        },
        query: {
          processBack: route.query.processBack,
          products: route.query.products
        }
      })
    }

    const activateSubnav = (slide) => {
      emitter.emit('play-btn-sound')
      showMore.value = true
      activeSlideContent.value = slide
      // get topics
      axios
        .get(`${process.env.VUE_APP_API_ENDPOINT}topics`)
        .then(response => {
          const children = response.data
            .filter(topic => topic.parent === slide.slug)
            .sort((a, b) => a.order_id - b.order_id)
          activeSlideContent.value.children = children
        })
    }

    const toggleContent = (child) => {
      emitter.emit('play-btn-sound')
      console.log(child)
      subContent.value = child
    }

    const close = () => {
      emitter.emit('play-btn-sound')
      showMore.value = false
      activeSlideContent.value = null
      subContent.value = {}
    }

    const visitAutohaus = () => {
      emitter.emit('play-btn-sound')
      router.push({
        name: 'Autohaus',
        query: {
          processBack: route.query.processBack,
          products: route.query.products
        }
      })
    }

    watch(route, () => {
      init()
    })

    onMounted(() => init())
    window.onresize = () => init()

    return {
      publicPath,
      slideElements,
      slides,
      slider,
      sliderElement,
      slidesContainer,
      clipPath,
      rotate,
      rotateTo,
      activeSlide,
      activeSlideContent,
      subContent,
      showMore,
      activateSubnav,
      toggleContent,
      close,
      visitAutohaus,
      isSafari
    }
  }
}
</script>


<style lang="scss" scoped>
.start {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-image: linear-gradient(to top, #fff 0%, #85a7c2 100%);
}

.latlong {
  position: absolute;
  width: 115%;
  left: 50%;
  top: 0;
  transform: translate(-50%, -15%);
  opacity: 0.1;
  pointer-events: none;
}

.rotate-slider{
  margin: 10vh auto 0 auto;
  pointer-events: none;

  ul.slides {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    position: relative;
    top: 0;
    left: 50%;
    transform-origin: center center;
    transition: all 0.75s ease-out;

    li {
      box-sizing: content-box;
      background-position: center;
      background: url('/static/img/assets.png') no-repeat center bottom;
      background-size: contain;
      display: block;
      color: #222;
      list-style: none;
      position: absolute;
      top: 0;
      left: 50%;
      text-align: center;
      transform-origin: bottom center;
      width: 100%;
      clip-path: url(#slideClip);

      img,
      video {
        position: absolute;
        bottom: 10%;
        bottom: 7%;
        transform: translateX(-50%);
        width: 80%;
        pointer-events: none;
      }

      .inner {
        position: relative;
        z-index: 1;
        padding: 2rem;
        margin: 0 10vw;
        border: 1px solid rgba($color: #000, $alpha: 0.1);

        h1 {
          margin: 0 0 1rem 0;
          font-size: 2.5rem;
          font-weight: 700;
          letter-spacing: -0.5pt;
        }

        button {
          pointer-events: all;
          font-size: 1.1rem;
          padding: 1rem 2rem;
        }
      }
    }
  }
}

.btn-prev,
.btn-next {
  position: absolute;
  bottom: 50%;
  background: #5b6e7f;
  border: none;
  cursor: pointer;
  border-radius: 0;

  svg {
    height: 2rem;
    fill: none;

    path {
      stroke: #fff;
      stroke-width: 2;
    }
  }
}

.btn-prev {
  left: 0;
  padding: 1rem 1rem 1rem 0.8rem;
}

.btn-next {
  right: 0;
  padding: 1rem 0.8rem 1rem 1rem;

  svg {
    transform: rotate(180deg);
  }
}

.subnav-container {
  position: absolute;
  left: 0;
  top: 12vh;
  width: 100%;
  height: calc(100% - 12vh);
  display: flex;
  flex-direction: column;

  .close {
    position: absolute;
    top: -10vh;
    right: 2vh;
    width: 8vh;
    height: 8vh;
    background: #fff;
    border: none;
    transition: all 250ms ease-in-out;
    cursor: pointer;

    svg {
      margin: 1.75vh;
      fill: #333;
      transition: all 250ms ease-in-out;
    }

    &:hover {
      background: #333;

      svg {
        fill: #fff;
        transform: rotate(180deg);
      }
    }
  }

  .subnav {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    height: auto;
    position: relative;
    z-index: 10;

    li {
      width: 100%;
      background: rgba($color: #5b6e7f, $alpha: 0.95);
      color: #fff;
      padding: 1.5rem 1.5rem 2.5rem 1.5rem;
      border-right: 1px solid rgba($color: #fff, $alpha: 0.25);
      border-bottom: 1px solid rgba($color: #fff, $alpha: 0.25);
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      transition: all 500ms ease-in-out;

      &:last-child {
        border-right: none;
      }

      &.active {
        background: rgba($color: #333, $alpha: 0.95);
        box-shadow: 0 0 2rem rgba($color: #000, $alpha: 0.2);
        z-index: 10;
      }

      span {
        height: 100%;
        text-align: center;
      }

      .id {
        position: absolute;
        font-family: 'Navada Outline', sans-serif;
        font-size: 5rem;
        height: 5rem;
        line-height: 1em;
        left: 15%;
        bottom: -0.045em;
        color: rgba($color: #fff, $alpha: 0.1);
      }

      .title {
        font-size: 1.2rem;
        line-height: 1.25em;
        font-weight: 600;
      }

      .touchpoint {
        transition: all 500ms ease-in-out;
        position: absolute;
        bottom: -1.25rem;

        &:hover {
          transform: scale(1.5);
        }
      }
    }
  }

  .content {
    color: #fff;
    display: flex;
    overflow: hidden;
    height: 100%;
    line-height: 1.5rem;
    animation-duration: 350ms;
    background: linear-gradient(180deg, rgba($color: #5b6e7f, $alpha: 0.95) 0%, rgba($color: #415566, $alpha: 0.95));

    .image {
      width: 50%;
      height: 100%;
      padding: 0 1.5rem;
      display: flex;
      align-items: flex-end;
      position: relative;

      img {
        width: 100%;
        display: block;
      }

      video,
      img.fallback {
        width: calc(100% - 3rem);
        display: block;
        position: absolute;
        left: 1.5rem;
        bottom: 0;
      }
    }

    .body {
      width: 50%;
      height: 100%;
      padding: 4rem;
      overflow: auto;
      animation-duration: 350ms;

      ::v-deep() p,
      ::v-deep() ul {
        margin-bottom: 2em;

        b {
          font-weight: 900;
        }
      }

      ::v-deep() ul {
        margin-left: 2rem;

        li {
          margin: 1rem 0;

          &::marker {
            font-size: 1.5rem;
            color: orange;
          }
        }
      }

      ::v-deep() sup {
        display: inline-block;
        font-size: 0.75rem;
        font-weight: 200;
        margin: -0.5em 0.25em 0 0.25em;
      }

      ::v-deep() a {
        color: rgb(181, 222, 226);

        &:hover {
          color: #fff;
        }
      }

      ::v-deep() img {
        max-width: 100%;
      }

      ::v-deep() .ql-size-small {
        display: block;
        font-size: 0.7rem;
        line-height: 1.25em;
        margin-bottom: -1.25rem;
        padding-bottom: 0.5rem;
        color: rgba($color: #fff, $alpha: 0.5);
        border-bottom: 1px solid rgba($color: #fff, $alpha: 0.1);
        word-break: break-all;
      }
    }
  }
}

.pagination {
  position: absolute;
  left: 50%;
  bottom: 3.75rem;
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  transform: translateX(-50%);

  li {
    width: 3rem;
    margin: 0 0.25rem;
    padding-bottom: 0.1rem;
    font-size: 1rem;
    transition: all 500ms ease-in-out;
    border-bottom: 1pt solid rgba($color: #000, $alpha: 0.15);
    line-height: 1.2rem;
    font-weight: 600;
    cursor: pointer;
    position: relative;
    color: rgba($color: #000, $alpha: 0.25);

    &.active,
    &:hover {
      font-weight: 900;
      font-size: 1.25rem;
      border-color: #000;
      color: #000;
    }

    .title {
      color: #fff;
      font-weight: 600;
      position: absolute;
      top: -1.5rem;
      left: 50%;
      transform: translate(-50%, -100%);
      background: #5b6e7f;
      padding: 0.75rem;
      text-align: center;
      font-size: 1rem;
      width: 20vw;
      opacity: 0;
      pointer-events: none;
      transition: all 250ms ease-in-out;

      &::before {
        content: '';
        position: absolute;
        width: 1rem;
        height: 1rem;
        background: #5b6e7f;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 50%) rotate(45deg);
      }
    }

    &:hover {
      .title {
        opacity: 1;
      }
    }
  }
}
</style>
