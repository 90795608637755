<template>
  <div class="edges top" />
  <div class="edges bottom" />
</template>


<style lang="scss" scoped>
.edges {
  &::before,
  &::after  {
    position: absolute;
    content: '';
    border: solid rgba($color: #000, $alpha: 0.75);
    width: 1rem;
    height: 1rem;
  }
}

.edges.top {
  &::before {
    border-width: 1pt 0 0 1pt;
    top: 0;
    left: 0;
  }

  &::after {
    border-width: 1pt 1pt 0 0;
    top: 0;
    right: 0;
  }
}

.edges.bottom {
  &::before {
    border-width: 0 0 1pt 1pt;
    bottom: 0;
    left: 0;
  }

  &::after {
    border-width: 0 1pt 1pt 0;
    bottom: 0;
    right: 0;
  }
}
</style>
